<template>
  <div class="container">
    <h2>Live Balance</h2>
    <p v-if="balance.last_updated">Last updated: {{ balance.last_updated }}</p>
    <table border="1" v-if="balance.last_updated">
      <tbody>
        <tr v-for="(entry, index) in singles" :key="index">
          <td>{{ entry[0] }}</td>
          <td>
            <span v-if="Number(entry[1]) < 0">
              ({{ Math.abs(Number(entry[1])).toFixed(2) }})
            </span>
            <span v-else>
              {{ Number(entry[1]).toFixed(2) }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <p v-else>No balance data.</p>
  </div>
</template>

<script>
export default {
  name: 'Balance',
  props: {
    balance: {
      type: Object,
      required: true
    }
  },

  computed: {
    singles() {
      // The fields we want to display along with their descriptive labels.
      const fields = {
        dtMarginBuyingPower: 'Intraday Margin Purchasing Power',
        dtCashBuyingPower: 'Intraday Cash Purchasing Power',
        marginBuyingPower: 'Overnight Margin Purchasing Power',
        cashBuyingPower: 'Overnight Cash Purchasing Power'
      };

      return Object.entries(fields).map(([key, label]) => {
        return [label, this.balance[key] !== undefined ? this.balance[key] : 0];
      });
    }
  }
};
</script>

<style scoped>
.container {
  width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #000;
  padding: 8px;
  text-align: right;
}

th:first-child,
td:first-child {
  text-align: left;
  width: auto; 
}
</style>
