<template>
  <div>
    <div class="header">
      <h2 class="title">Monthly Performance: {{ formattedMonthStart }} - {{ formattedMonthEnd }}</h2>
      <p class="title no-print" v-if="lastUpdatedTime">Last updated: {{ lastUpdatedDate }} {{ lastUpdatedTime }}</p>
    </div>
    <button class="print-button no-print" @click="printPage">🖨 Print</button>
    
    <!-- Loading State -->
    <div v-if="loading" class="loading-container">
      <p>Loading...</p>
    </div>

    <!-- Performance Table -->
    <table v-else border="1">
      <thead>
        <tr>
          <th class="symbols-column">Symbol</th>
          <th v-for="account in accounts" :key="account.account_id">x{{ account.account_id }}</th>
          <th class="totals-column">Total</th>
        </tr>
      </thead>
      <tbody v-if="symbols.length > 0">
        <tr v-for="symbol in symbols" :key="symbol">
          <td class="symbols-column">{{ symbol }}</td>
          <td v-for="account in accounts" :key="account.account_id">
            {{ formatNumber(account.symbolData[symbol]?.pnl ?? 0) }}
          </td>
          <td class="totals-column">{{ formatNumber(rowTotals[symbol]) }}</td>
        </tr>
        <tr class="totals-row">
          <td class="symbols-column"><strong>Total</strong></td>
          <td v-for="account in accounts" :key="account.account_id">{{ formatNumber(columnTotals[account.account_id]) }}</td>
          <td class="totals-column">{{ formatNumber(grandTotal) }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="3">No performance data available</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "MonthlyPerformance",
  props: {
    start_date: {
      type: String, // in MMDDYYYY format
      required: true,
    },
    apiEndpoint: {
      type: String,
      default: "/api/strats/performance",
    },
  },
  data() {
    return {
      performances: [],
      accounts: [],
      symbols: [],
      rowTotals: {},
      columnTotals: {},
      grandTotal: 0,
      lastUpdatedTime: null,
      lastUpdatedDate: null,
      maxLastUpdatedStr: null,
      loading: false,
    };
  },
  watch: {
    start_date: {
      immediate: true,
      handler() {
        this.fetchAndAggregateData();
      },
    },
  },
  computed: {
    monthStartDate() {
      return this.getMonthStart(this.start_date);
    },
    monthEndDate() {
      return this.getMonthEnd(this.start_date);
    },
    formattedMonthStart() {
      return this.formatDateForDisplay(this.formatDateForAPI(this.monthStartDate));
    },
    formattedMonthEnd() {
      return this.formatDateForDisplay(this.formatDateForAPI(this.monthEndDate));
    },
  },
  methods: {
    async fetchAndAggregateData() {
      if (!this.start_date) {
        this.resetData();
        return;
      }

      this.loading = true; 

      const apiStartDate = this.formatDateForAPI(this.monthStartDate);
      const apiEndDate = this.formatDateForAPI(this.monthEndDate);

      const queryParams = new URLSearchParams({ 
        startDate: apiStartDate,
        endDate: apiEndDate
      }).toString();

      try {
        const res = await fetch(`${this.apiEndpoint}?${queryParams}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'same-origin',
        });

        if (!res.ok) {
          console.error(`HTTP error! status: ${res.status} for monthly range`);
          this.resetData();
          this.loading = false;
          return;
        }

        const json = await res.json();
        const arrayPerformances = Object.entries(json).map(([account_id, value]) => ({
          account_id,
          symbols: value.symbols || [],
          last_updated: value.last_updated
        }));

        this.performances = arrayPerformances;
        this.prepareData();
      } catch (error) {
        console.error('API request error:', error);
        this.resetData();
      }

      this.loading = false;
    },

    prepareData() {
      if (!this.performances || this.performances.length === 0) {
        this.resetData();
        return;
      }

      const accountsData = {};
      const symbolsSet = new Set();

      this.maxLastUpdatedStr = null;

      for (const perf of this.performances) {
        const accountId = perf.account_id;
        if (!accountsData[accountId]) {
          accountsData[accountId] = { account_id: accountId, symbolData: {} };
        }
        (perf.symbols || []).forEach(sym => {
          if (sym && sym.symbol && typeof sym.pnl === "number") {
            const existing = accountsData[accountId].symbolData[sym.symbol]?.pnl || 0;
            accountsData[accountId].symbolData[sym.symbol] = {
              pnl: existing + sym.pnl
            };
            symbolsSet.add(sym.symbol);
          }
        });

        if (perf.last_updated) {
          if (!this.maxLastUpdatedStr || perf.last_updated > this.maxLastUpdatedStr) {
            this.maxLastUpdatedStr = perf.last_updated;
          }
        }
      }

      if (this.maxLastUpdatedStr) {
        const [isoDatePart, timePart, tz] = this.maxLastUpdatedStr.split(" ");
        this.lastUpdatedDate = this.convertISODateToMMDDYYYY(isoDatePart);
        this.lastUpdatedTime = `${timePart} ${tz}`;
      }

      const accounts = Object.values(accountsData);
      const rowTotals = {};
      const columnTotals = {};
      let grandTotal = 0;

      accounts.forEach((account) => {
        let accountTotal = 0;
        Object.entries(account.symbolData).forEach(([symbol, data]) => {
          const pnl = data.pnl || 0;
          rowTotals[symbol] = (rowTotals[symbol] || 0) + pnl;
          accountTotal += pnl;
        });
        columnTotals[account.account_id] = accountTotal;
        grandTotal += accountTotal;
      });

      this.accounts = accounts;
      this.symbols = Array.from(symbolsSet).sort();
      this.rowTotals = rowTotals;
      this.columnTotals = columnTotals;
      this.grandTotal = grandTotal;
    },

    getMonthStart(dateStr) {
      const date = this.parseDate(dateStr);
      date.setDate(1);
      return date;
    },

    getMonthEnd(dateStr) {
      const start = this.getMonthStart(dateStr);
      const nextMonth = new Date(start.getFullYear(), start.getMonth() + 1, 1);
      const end = new Date(nextMonth.getTime() - 24 * 60 * 60 * 1000);
      return end;
    },

    parseDate(dateStr) {
      const mm = parseInt(dateStr.slice(0, 2), 10);
      const dd = parseInt(dateStr.slice(2, 4), 10);
      const yyyy = parseInt(dateStr.slice(4, 8), 10);
      return new Date(yyyy, mm - 1, dd);
    },

    formatDateForAPI(date) {
      const mm = String(date.getMonth() + 1).padStart(2, '0');
      const dd = String(date.getDate()).padStart(2, '0');
      const yyyy = date.getFullYear();
      return `${mm}${dd}${yyyy}`;
    },

    formatDateForDisplay(dateStr) {
      const match = dateStr.match(/(\d{2})(\d{2})(\d{4})/);
      return match ? `${match[1]}/${match[2]}/${match[3]}` : dateStr;
    },

    convertISODateToMMDDYYYY(isoDate) {
      const [yyyy, mm, dd] = isoDate.split('-');
      return `${mm}/${dd}/${yyyy}`;
    },

    resetData() {
      this.accounts = [];
      this.symbols = [];
      this.rowTotals = {};
      this.columnTotals = {};
      this.grandTotal = 0;
      this.lastUpdatedTime = null;
      this.lastUpdatedDate = null;
      this.maxLastUpdatedStr = null;
    },
    printPage() {
      window.print();
    },

    formatNumber(val) {
      const num = Number(val);
      if (num < 0) {
        return '(' + Math.abs(num).toFixed(2) + ')';
      }
      return num.toFixed(2);
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.title {
  margin-top: 10px;
  margin-bottom: 10px;
}

table {
  border-collapse: collapse;
}

thead th {
  padding: 8px;
  text-align: right;
  border-bottom: 2px solid #333;
}

thead th:first-child {
  text-align: left;
}

tbody tr td {
  padding: 8px;
  padding-left: 12px;
  text-align: right;
}

tbody tr td:first-child {
  text-align: left;
}

/* Thicker borders for symbol and total columns */
.symbols-column {
  border-right: 2px solid #333;
}

.totals-column {
  border-left: 2px solid #333;
}

/* Thicker border before the totals row */
.totals-row td {
  border-top: 2px solid #333;
}

.loading-container {
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
}

.print-button {
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  color: black;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -webkit-appearance: none; 
  -moz-appearance: none;    
  appearance: none;         
  font-family: 'Inter', sans-serif;
}

.no-print {
  margin-bottom: 20px;
}

@media print {
  .no-print {
    display: none !important;
  }

  @page {
    size: A4 portrait;
    margin: 1cm;
  }

  table {
    font-size: 12px;
  }
}
</style>
