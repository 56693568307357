<template>
    <main class="main-container">
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap" rel="stylesheet">
        <div class="header">
            <h1>Executive Summary</h1>
            <div class="selections">
                <div class="view-select">
                    <h3 class="view-label">Type:</h3>
                    <select 
                        id="view-mode" 
                        class="view-input"
                        v-model="selectedView"
                    >
                        <option value="daily">Daily</option>
                        <option value="range">Date Range</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>                        
                    </select>
                </div>

                <!-- For daily, weekly, monthly: single date input -->
                <!-- For range: two date inputs -->
                <div class="date-select" v-if="selectedView !== 'range'">
                    <h3 class="date-label">Date:</h3>
                    <input 
                        type="date" 
                        id="start-date" 
                        class="date-input"
                        v-model="formattedInputDate" 
                    />
                </div>

                <div class="date-select" v-else>
                    <div class="select-flex">
                        <div class="view-select">
                            <h3 class="date-label">Start Date:</h3>
                            <input 
                                type="date"
                                class="date-input"
                                v-model="formattedRangeStart" 
                            />
                        </div>
                        <div class="view-select">
                            <h3 class="date-label">End Date:</h3>
                            <input 
                                type="date" 
                                class="date-input"
                                v-model="formattedRangeEnd"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <router-link class="link" to="/">Back to Home Page</router-link>
        
        <div class="main">
            <div class="performance-container">
                <!-- Daily view -->
                <DailyPerformance 
                    v-if="selectedView === 'daily'" 
                    :start_date="start_date"
                />
                
                <!-- Date Range view -->
                <DateRangePerformance
                    v-if="selectedView === 'range'"
                    :start_date="start_date" 
                    :end_date="end_date"
                />

                <!-- Weekly view -->
                <WeeklyPerformance
                    v-if="selectedView === 'weekly'"
                    :start_date="start_date" 
                />
                
                <!-- Monthly view -->
                <MonthlyPerformance
                    v-if="selectedView === 'monthly'"
                    :start_date="start_date"
                />
            </div>
        </div>
    </main>
</template>

<script>
import DailyPerformance from './DailyPerformance.vue';
import DateRangePerformance from './DateRangePerformance.vue';
import WeeklyPerformance from './WeeklyPerformance.vue';
import MonthlyPerformance from './MonthlyPerformance.vue';

export default {
    name: 'PerformancePage',
    components: { DailyPerformance, DateRangePerformance, WeeklyPerformance, MonthlyPerformance },
    data() {
        return {
            start_date: '',
            end_date: '',
            selectedView: 'daily',
        };
    },

    created() {
        if (!this.$store.state.username) {
            this.$router.push({ name: 'Login' });
        }
        this.setDefaultStartDate();
    },

    computed: {
        formattedInputDate: {
            get() {
                // Convert `start_date` (MMDDYYYY) to `YYYY-MM-DD`
                if (this.start_date.length === 8) {
                    const mm = this.start_date.slice(0, 2);
                    const dd = this.start_date.slice(2, 4);
                    const yyyy = this.start_date.slice(4, 8);
                    return `${yyyy}-${mm}-${dd}`;
                }
                return '';
            },
            set(value) {
                // Convert `YYYY-MM-DD` to `MMDDYYYY`
                if (value) {
                    const [yyyy, mm, dd] = value.split('-');
                    this.start_date = `${mm}${dd}${yyyy}`;
                }
            },
        },
        formattedRangeStart: {
            get() {
                if (this.start_date.length === 8) {
                    const mm = this.start_date.slice(0, 2);
                    const dd = this.start_date.slice(2, 4);
                    const yyyy = this.start_date.slice(4, 8);
                    return `${yyyy}-${mm}-${dd}`;
                }
                return '';
            },
            set(value) {
                if (value) {
                    const [yyyy, mm, dd] = value.split('-');
                    this.start_date = `${mm}${dd}${yyyy}`;
                }
            },
        },
        formattedRangeEnd: {
            get() {
                if (this.end_date.length === 8) {
                    const mm = this.end_date.slice(0, 2);
                    const dd = this.end_date.slice(2, 4);
                    const yyyy = this.end_date.slice(4, 8);
                    return `${yyyy}-${mm}-${dd}`;
                }
                return '';
            },
            set(value) {
                if (value) {
                    const [yyyy, mm, dd] = value.split('-');
                    this.end_date = `${mm}${dd}${yyyy}`;
                }
            },
        },
    },

    methods: {
        setDefaultStartDate() {
            const recentWeekday = this.getRecentWeekdayEST();
            this.start_date = this.formatDateForAPI(recentWeekday);
            this.end_date = this.start_date; 
        },

        getRecentWeekdayEST() {
            const now = new Date();
            const utcOffset = now.getTimezoneOffset(); 
            const estOffset = -300; 
            const estDate = new Date(now.getTime() + (estOffset - utcOffset) * 60 * 1000);

            const day = estDate.getDay();
            if (day === 0) { 
                estDate.setDate(estDate.getDate() - 2);
            } else if (day === 6) { 
                estDate.setDate(estDate.getDate() - 1);
            }

            return estDate;
        },

        formatDateForAPI(date) {
            const mm = String(date.getMonth() + 1).padStart(2, '0');
            const dd = String(date.getDate()).padStart(2, '0');
            const yyyy = date.getFullYear();
            return `${mm}${dd}${yyyy}`; // MMDDYYYY format
        },
    },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.selections {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main-container {
  padding-left: 50px;
  padding-right: 50px;
  font-family: 'Inter', Arial, sans-serif;
}

.date-select, .view-select {
  margin-left: auto;
  margin-right: 20px;
  padding-right: 10px;
}

.select-flex {
    display: flex;
}

.date-input, .view-input {
  width: 160px;
  padding: 12px 16px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  font-family: 'Inter', sans-serif;
}

.date-label, .view-label {
  display: block;
  margin-bottom: 8px;
}

@media print {
  @page {
    margin: 0; /* Remove default margins so there's no room for headers/footers */
  }

  /* Hide other elements as before */
  .header,
  .selections,
  .view-select,
  .date-select,
  .link {
    display: none !important;
  }

  /* Ensure performance container is visible and formatted */
  .performance-container {
    display: block !important;
  }

  .performance-container table {
    width: 100% !important;
    border-collapse: collapse !important;
  }
  
  .performance-container th,
  .performance-container td {
    border: 1px solid #ddd !important;
    padding: 8px !important;
    text-align: right !important;
  }
  
  .performance-container th:first-child,
  .performance-container td:first-child {
    text-align: left !important;
  }
}
</style>
