<template>
  <div class="symbol-input-container">
    <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap" rel="stylesheet">
    <button class="add-button" @click="handleAdd">Add</button>
    <input
      type="text"
      v-model="uppercaseInput"
      placeholder="Type Symbol (e.g. AAPL, BRK_B)"
      class="symbol-input"
      :class="inputClass"
    >
  </div>
</template>

<script>
  export default {
    name: 'SymbolInput',

    data() {
      return {
        input: '',
        options: [],  
      };
    },
    computed: {
      uppercaseInput: {
        get() {
          return this.input;
        },
        set(value) {
          this.input = value.toUpperCase();
        }
      },
      isInOptions() {
        return true;
      },
      inputClass() {
        return {
          'input-green': this.isInOptions && this.uppercaseInput !== '',
          'input-red': !this.isInOptions && this.uppercaseInput !== ''
        };
      }
    },
    methods: {
      handleAdd() {
        if (this.isInOptions && this.uppercaseInput !== '') {
          this.$emit('add', this.uppercaseInput);
          this.uppercaseInput = '';
        }
      },
    }
  };
</script>
  
<style>

  .symbol-input {
    width: 100%;
    padding: 12px 16px;
    font-size: 16px;
    color: #333;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    -webkit-appearance: none; 
    -moz-appearance: none;    
    appearance: none;        
    font-family: 'Inter', sans-serif;
  }

  .symbol-input:focus {
    outline: none;
  }

  .input-green {
    border: 2px solid #76EE59;
  }

  .input-red {
    border: 2px solid #D22B2B;
  }

  .symbol-input-container {
    font-family: 'Inter', sans-serif; 
    width: 100%; 
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .add-button {
    cursor: pointer;
    padding: 12px 16px;
    margin-right: 10px;
    font-size: 16px;
    color: #333;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    -webkit-appearance: none; 
    -moz-appearance: none;    
    appearance: none;         
    font-family: 'Inter', sans-serif;
  }

</style>
  