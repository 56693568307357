<template>
    <div>
        <h2 class="title">Orders</h2>
        
        <div class="account">
            <ETradeDropdown 
                :selected_account_id="selected_account_id"
                :account_ids="account_ids"
                @update:selected_account_id="handleAccountIdUpdate"
            />
        </div>

      <table border="1" v-if="orders.length > 0">
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Action</th>
            <th>Type</th>
            <th>Time</th>
            <th>OrderID</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(order, index) in orders"
            :key="order.orderId"
            :class="{ 'new-symbol-group': index === 0 || order.symbol !== orders[index - 1].symbol }"
          >
            <td>{{ order.symbol }}</td>
            <td>{{ order.filledQuantity }}</td>
            <td>{{ Number(order.executedPrice).toFixed(2) }}</td>
            <td>{{ order.orderAction }}</td>
            <td>{{ order.priceType }}</td>
            <td>{{ order.executedTime }}</td>
            <td>{{ order.orderId }}</td>
          </tr>
        </tbody>
      </table>
      <p v-else>No executed orders.</p>
    </div>
  </template>
  
  <script>
  import ETradeDropdown from '../common/ETradeDropdown.vue';

  export default {
    name: 'DailyOrders',
    components: { ETradeDropdown },
    props: {
      performances: {
        type: Object,
        required: true
      }
    },
  
    data() {
      return {
        account_ids: [
        { value: '9365', text: 'x9365' },
        { value: '9126', text: 'x9126' },
        { value: '9066', text: 'x9066' },
        { value: '9776', text: 'x9776' },
        { value: '9206', text: 'x9206' },
        { value: '9716', text: 'x9716' },
      ],
      selected_account_id: '9365',
        alerts: {}
      };
    },
  
    computed: {
        orders() {
            const performance = this.performances[this.selected_account_id] || {};
            const orders = [];
            if (performance && performance.symbols) {
                for (const symbolObj of Object.values(performance.symbols)) { // Use 'performance', not 'this.performance'
                if (symbolObj && Array.isArray(symbolObj.orders)) {
                    for (const order of symbolObj.orders) {
                    const new_order = {
                        symbol: symbolObj.symbol,
                        filledQuantity: order.filledQuantity,
                        executedPrice: order.executedPrice,
                        orderAction: order.orderAction,
                        priceType: order.priceType,
                        executedTime: order.executedTime.split(' ')[1],
                        orderId: order.orderId,
                    };
                    orders.push(new_order);
                    }
                }
                }
            }
            return orders;
            }

    },
  
    methods: {
        handleAccountIdUpdate(newAccountId) {
            this.selected_account_id = newAccountId;
        },
    }
  };
  </script>
  
  <style scoped>
  .account {
    margin-bottom: 25px;
  }

  .title {
    margin-top: 10px;
    margin-bottom: 0px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th,
  td {
    border: 1px solid #000;
    padding: 8px;
    text-align: right;
  }
  
  th:first-child,
  td:first-child {
    text-align: left;
    width: auto;
  }
  
  .new-symbol-group td {
    border-top: 2px solid #000; /* Heavier border line for new symbol groups */
  }
  
  </style>
  