<template>
  <div class="container">
    <div class="performance">
      <h2 class="title">Daily Performance: {{ formattedStartDate }}</h2>
      <p class="title no-print" v-if="lastUpdatedTime">Last updated: {{ lastUpdatedDate }} {{ lastUpdatedTime }}</p>
      <button class="print-button no-print" @click="printPage">🖨 Print</button>
      <button class="orders-button no-print" @click="toggleOrders">{{ showOrders ? 'Hide Orders' : 'View Orders' }}</button>

      <!-- Loading State -->
      <div v-if="loading" class="loading-container">
        <p>Loading...</p>
      </div>

      <!-- Performance Table -->
      <table v-else border="1">
        <thead>
          <tr>
            <th class="symbols-column">Symbol</th>
            <th v-for="account in accounts" :key="account.account_id">x{{ account.account_id }}</th>
            <th class="totals-column">Total</th>
          </tr>
        </thead>
        <tbody v-if="symbols.length > 0">
          <tr v-for="symbol in symbols" :key="symbol">
            <td class="symbols-column">{{ symbol }}</td>
            <td v-for="account in accounts" :key="account.account_id">
              {{ formatNumber(account.symbolData[symbol]?.pnl ?? 0) }}
            </td>
            <td class="totals-column">{{ formatNumber(rowTotals[symbol]) }}</td>
          </tr>
          <tr class="totals-row">
            <td class="symbols-column"><strong>Total</strong></td>
            <td v-for="account in accounts" :key="account.account_id">{{ formatNumber(columnTotals[account.account_id]) }}</td>
            <td class="totals-column">{{ formatNumber(grandTotal) }}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="3">No performance data available</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="orders no-print" v-if="showOrders && !loading">
      <DailyOrders :performances="ordersPerformances" />
    </div>
  </div>
</template>

<script>
import DailyOrders from './DailyOrders.vue';

export default {
  name: "DailyPerformance",
  components: { DailyOrders },
  props: {
    start_date: {
      type: String, // MMDDYYYY
      required: true,
    },
    apiEndpoint: {
      type: String,
      default: "/api/strats/performance",
    },
  },
  data() {
    return {
      performances: [],
      ordersPerformances: {},
      accounts: [],
      symbols: [],
      rowTotals: {},
      columnTotals: {},
      grandTotal: 0,
      lastUpdatedTime: null,
      lastUpdatedDate: null,
      maxLastUpdatedStr: null,
      loading: false,
      showOrders: false, // Track whether to show the orders component
    };
  },
  computed: {
    formattedStartDate() {
      const match = this.start_date.match(/(\d{2})(\d{2})(\d{4})/);
      return match ? `${match[1]}/${match[2]}/${match[3]}` : "";
    },
  },
  watch: {
    start_date: {
      immediate: true,
      handler() {
        this.fetchData();
      }
    }
  },
  methods: {
    toggleOrders() {
      this.showOrders = !this.showOrders;
    },
    async fetchData() {
      if (!this.start_date) {
        this.resetData();
        return;
      }

      this.loading = true;
      const queryParams = new URLSearchParams({ 
        startDate: this.start_date,
        endDate: this.start_date
      }).toString();

      try {
        const res = await fetch(`${this.apiEndpoint}?${queryParams}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'same-origin',
        });

        if (!res.ok) {
          console.error(`HTTP error! status: ${res.status} for daily performance`);
          this.resetData();
          this.loading = false;
          return;
        }

        const json = await res.json();
        const ordersPerformances = json;
        this.ordersPerformances = ordersPerformances;
        const arrayPerformances = Object.entries(json).map(([account_id, value]) => ({
          account_id,
          symbols: value.symbols || [],
          last_updated: value.last_updated
        }));

        this.performances = arrayPerformances;
        this.prepareData();
      } catch (error) {
        console.error('API request error:', error);
        this.resetData();
      }

      this.loading = false;
    },

    prepareData() {
      if (!this.performances || this.performances.length === 0) {
        this.resetData();
        return;
      }

      const accountsData = {};
      const symbolsSet = new Set();

      this.maxLastUpdatedStr = null;

      for (const perf of this.performances) {
        const accountId = perf.account_id;
        if (!accountsData[accountId]) {
          accountsData[accountId] = { account_id: accountId, symbolData: {} };
        }
        (perf.symbols || []).forEach(sym => {
          if (sym && sym.symbol && typeof sym.pnl === "number") {
            const existing = accountsData[accountId].symbolData[sym.symbol]?.pnl || 0;
            accountsData[accountId].symbolData[sym.symbol] = {
              pnl: existing + sym.pnl
            };
            symbolsSet.add(sym.symbol);
          }
        });

        if (perf.last_updated) {
          if (!this.maxLastUpdatedStr || perf.last_updated > this.maxLastUpdatedStr) {
            this.maxLastUpdatedStr = perf.last_updated;
          }
        }
      }

      if (this.maxLastUpdatedStr) {
        const [isoDatePart, timePart, tz] = this.maxLastUpdatedStr.split(" ");
        this.lastUpdatedDate = this.convertISODateToMMDDYYYY(isoDatePart);
        this.lastUpdatedTime = `${timePart} ${tz}`;
      }

      const accounts = Object.values(accountsData);
      const rowTotals = {};
      const columnTotals = {};
      let grandTotal = 0;

      accounts.forEach((account) => {
        let accountTotal = 0;
        Object.entries(account.symbolData).forEach(([symbol, data]) => {
          const pnl = data.pnl || 0;
          rowTotals[symbol] = (rowTotals[symbol] || 0) + pnl;
          accountTotal += pnl;
        });
        columnTotals[account.account_id] = accountTotal;
        grandTotal += accountTotal;
      });

      // Define desired order
      const desiredOrder = ["9365", "9126", "9066", "9776", "9206", "9716"];

      // Sort the accounts based on the desired order
      accounts.sort((a, b) => {
        return desiredOrder.indexOf(a.account_id) - desiredOrder.indexOf(b.account_id);
      });

      this.accounts = accounts;
      this.symbols = Array.from(symbolsSet).sort();
      this.rowTotals = rowTotals;
      this.columnTotals = columnTotals;
      this.grandTotal = grandTotal;
    },

    convertISODateToMMDDYYYY(isoDate) {
      const [yyyy, mm, dd] = isoDate.split('-');
      return `${mm}/${dd}/${yyyy}`;
    },

    resetData() {
      this.performances = [];
      this.accounts = [];
      this.symbols = [];
      this.rowTotals = {};
      this.columnTotals = {};
      this.grandTotal = 0;
      this.lastUpdatedTime = null;
      this.lastUpdatedDate = null;
      this.maxLastUpdatedStr = null;
    },

    printPage() {
      window.print();
    },

    formatNumber(val) {
      const num = Number(val);
      if (num < 0) {
        return '(' + Math.abs(num).toFixed(2) + ')';
      }
      return num.toFixed(2);
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  padding: 5px;
}

.orders {
  padding-left: 75px;
}

.title {
  margin-top: 10px;
  margin-bottom: 10px;
}

table {
  border-collapse: collapse;
}

thead th {
  padding: 8px;
  text-align: right;
  border-bottom: 2px solid #333;
}

thead th:first-child {
  text-align: left;
}

tbody tr td {
  padding: 8px;
  padding-left: 12px;
  text-align: right;
}

tbody tr td:first-child {
  text-align: left;
}

.totals-row td {
  border-top: 2px solid #333; /* Slightly more weighted line above totals row */
}

.totals-column {
  border-left: 2px solid #333;
}

.symbols-column {
  border-right: 2px solid #333;
}

.loading-container {
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
}

.print-button,
.orders-button {
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 1; /* Ensure a consistent line-height */
  color: black;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: 'Inter', Arial, sans-serif;
  vertical-align: middle; /* Helps align if they’re inline-block */
}

.orders-button {
  margin-left: 10px;
}


.no-print {
  margin-bottom: 20px;
}

@media print {
  .no-print {
    display: none !important;
  }

  @page {
    size: portrait;
    margin: 1cm;
  }

  table {
    font-size: 12px;
  }
}
</style>
